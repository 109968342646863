<template>
  <b-card no-body>
    <p class="m-3">{{selection ? 'Upload media to select' : 'Upload media'}}</p>
    <vue-dropzone
      id="dropzone-textree-media"
      :options="dropzoneOptions"
      @vdropzone-sending="dzSending"
      @vdropzone-success="dzSuccess"></vue-dropzone>
    <b-table hover small :items="files" :fields="fields">
      <template #cell(thumb_url)="data">
        <img :src="data.item.thumb_url" style="width: 160px;" alt="">
      </template>
      <template #cell(name)="data" v-if="!selection">
        <b-link :href="data.item.url" target="_blank">{{data.item.name}}</b-link>
      </template>
      <template #cell(selection)="data" v-if="selection">
        <b-button size="sm" variant="outline-secondary" @click="$emit('select', data.item)">Select image</b-button>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import vue2Dropzone from 'vue2-dropzone'
import store from '@/store'
import api from '@/api'

export default {
  components: {
    vueDropzone: vue2Dropzone
  },
  model: {
    prop: 'selected',
    event: 'select'
  },
  props: {
    selection: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    dropzoneOptions: {
      url: '/api/media/upload',
      method: 'post',
      paramName: 'file',
      thumbnailWidth: 150,
      thumbnailMethod: 'contain',
      maxFilesize: 24 * 1024 * 1024,
      acceptedFiles: 'image/*'
    },
    files: [],
    fields: [
      {
        key: 'thumb_url',
        label: 'Thumbnail'
      },
      {
        key: 'name',
        label: 'Filename'
      }
    ],
  }),
  mounted () {
    if (this.selection) {
      this.fields.push({
        key: 'selection',
        label: 'Select'
      })
    }
    this.getFiles()
  },
  methods: {
    dzSending: function (file, xhr, formData) {
      formData.append('token', store.state.admin.session.token);
    },
    dzSuccess: function () {
      this.getFiles()
    },
    getFiles: async function () {
      let files = await api.admin.media.getFiles()
      if (files) {
        this.files = files
      }
    }
  }
}
</script>

<style scoped>
</style>
